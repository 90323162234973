<template>
  <div v-frag>
    <v-row>
      <template v-if="branches.length">
        <template v-for="obBranch in branches">
          <v-col cols="12" lg="6" :key="getKey(obBranch)">
            <branch-form-dialog
              @saved="loadBranches"
              :value="obBranch"
              :secondary="secondary"
            />
          </v-col>
        </template>
      </template>
    </v-row>
    <v-row justify="center" v-if="obCompanyBranch">
      <v-col cols="12" lg="6" xl="4">
        <branch-form-dialog
          @saved="loadBranches"
          :value="obCompanyBranch"
          :secondary="secondary"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  Branch,
  BranchCollection,
  CompanyData,
  CustomerData,
} from "@planetadeleste/vue-mc-gw";
import BranchFormDialog from "@/modules/companies/components/BranchFormDialog.vue";
import { LocationModule } from "@/store/location";
import { uniqueId } from "lodash";

@Component({
  components: { BranchFormDialog },
})
export default class BranchesForm extends Vue {
  obBranches: BranchCollection = new BranchCollection();
  obCompanyBranch: Branch | null = null;

  @Prop(Number) readonly companyId!: number;
  @Prop(Object) readonly value!: CompanyData | CustomerData;
  @Prop(Boolean) readonly secondary!: boolean;

  get section() {
    return this.$route.name?.split(".")[0];
  }

  get isCustomer() {
    return this.section == "customers" || this.section == "invoices";
  }

  get branches() {
    return this.obBranches.getModels();
  }

  async mounted() {
    await this.loadBranches();
  }

  async loadBranches() {
    await this.setDefaultBranch();
    let iCompanyID = null;

    if (this.companyId) {
      iCompanyID = this.companyId;
    } else if (this.value && this.value.id) {
      iCompanyID = this.value.id;
    }

    if (!iCompanyID) {
      return;
    }

    this.obBranches.clear();

    if (this.secondary && !this.isCustomer) {
      this.obBranches.filterBy({ secondary: 1 });
    }

    if (this.isCustomer) {
      this.obBranches.filterBy({ customer: iCompanyID });
    } else {
      this.obBranches.byCompany(iCompanyID);
    }

    await this.obBranches.page(1).fetch();

    this.obBranches.sort((obBranch) => {
      return obBranch.is_default ? 1 : 99;
    });
  }

  async setDefaultBranch() {
    this.obCompanyBranch = new Branch({
      firm: {
        //@ts-ignore
        state_id: this.value
          ? this.value.firm.state_id
          : LocationModule.defaultState?.id,
      },
      active: true,
    });
    this.obCompanyBranch.set("ucfe", {});
  }

  getKey(item: Branch) {
    return `c${item.company_id}-b${item.id}-${uniqueId()}`;
  }
}
</script>
